import React from 'react';
import ReactModal, { Props } from 'react-modal';

ReactModal.setAppElement('#___gatsby');
ReactModal.defaultStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .05)',
  },
  content: {
    outline: 'none',
  },
};

export const Modal: React.FC<Props> = (props) => {
  return (
    <ReactModal
      {...props}
      shouldCloseOnOverlayClick={true}
      className="outline-none flex h-full items-center fadeIn"
    >
      <div
        onClick={props.onRequestClose}
        className="modal-close absolute top-0 right-0 m-10 cursor-pointer flex flex-col items-center text-white text-sm z-50"
      >
        <svg
          className="fill-current text-black"
          xmlns="http://www.w3.org/2000/svg"
          width={36}
          height={36}
          viewBox="0 0 18 18"
        >
          <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
        </svg>
      </div>
      <div className="relative overflow-hidden w-full h-full flex items-center justify-center p-4">
        <div
          className="overlay modal-close absolute top-0 right-0 bottom-0 left-0"
          onClick={props.onRequestClose}
        />
        {props.children}
      </div>
    </ReactModal>
  );
};
