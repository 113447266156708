import React, { useCallback, useEffect, useState } from 'react';
import Img from 'gatsby-image';
import { useEmblaCarousel } from 'embla-carousel/react';
import * as $ from './workimages.module.css';

type Image =
  | GatsbyTypes.WorkplaceImageFragmentFragment
  | GatsbyTypes.WorkplaceImageFragment2Fragment;
export type Images = ReadonlyArray<GatsbyTypes.Maybe<Image>>;

type Props = {
  images?: Images;
  onRequestClose: () => void;
  initialIdx?: number;
};

export const Thumb = ({
  selected,
  onClick,
  image,
}: {
  selected: boolean;
  onClick: () => void;
  image: GatsbyTypes.GatsbyPrismicImageFixedFragment;
}) => (
  <div
    className={`${$.embla__slide} ${$.embla__slideThumb} ${
      selected ? $.isSelected : ''
    }`}
  >
    <button
      onClick={onClick}
      className={`${$.embla__slide__inner} ${$.embla__slide__innerThumb}`}
      type="button"
    >
      <Img
        loading="lazy"
        critical={false}
        fixed={image}
        className={$.embla__slide__thumbnail}
        style={{ position: 'absolute' }}
      />
    </button>
  </div>
);

const EmblaCarousel = (props: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(props.initialIdx ?? 0);
  const [mainViewportRef, embla] = useEmblaCarousel({
    selectedClass: $.isSelected,
    draggableClass: $.isDraggable,
    draggingClass: $.isDragging,
    startIndex: props.initialIdx,
    dragFree: true,
    loop: true,
  });
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    selectedClass: '',
    draggableClass: $.isDraggable,
    draggingClass: $.isDragging,
    startIndex: props.initialIdx,
    containScroll: 'keepSnaps',
    dragFree: true,
    loop: true,
  });

  const drag = React.useRef({
    drag: false,
    x: 0,
    y: 0,
  });

  const onThumbClick = useCallback(
    (index) => {
      if (!embla || !emblaThumbs || drag.current.drag) return;
      if (emblaThumbs.clickAllowed()) embla.scrollTo(index);
    },
    [embla, emblaThumbs, drag],
  );

  const onImgClick = useCallback(() => {
    if (!embla || drag.current.drag) return;
    if (embla.clickAllowed()) embla.scrollNext();
  }, [embla, drag]);

  const onSelect = useCallback(() => {
    if (!embla || !emblaThumbs) return;
    const selected = embla.selectedScrollSnap();
    setSelectedIndex(selected);
    emblaThumbs.scrollTo(selected);
  }, [embla, emblaThumbs, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  React.useEffect(() => {
    function listener(e: KeyboardEvent) {
      if (!embla) return;
      switch (e.code) {
        case 'Escape':
          props.onRequestClose();
          break;
        case 'Enter':
        case 'Space':
        case 'ArrowRight':
        case 'ArrowDown': {
          if (
            (e.code === 'Enter' || e.code === 'Space') &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            e.target?.tagName === 'BUTTON'
          ) {
            return;
          }
          e.preventDefault();
          embla.scrollNext();

          break;
        }

        case 'ArrowLeft':
        case 'ArrowUp': {
          e.preventDefault();
          embla.scrollPrev();
          break;
        }
      }
    }

    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [embla, props.onRequestClose]);

  React.useEffect(() => {
    const dragEnd = (event: GlobalEventHandlersEventMap['mousedown']) => {
      const prev = drag.current;

      drag.current = {
        drag:
          Math.abs(event.pageX - prev.x) > 3 ||
          Math.abs(event.pageY - prev.y) > 3,
        x: 0,
        y: 0,
      };
    };
    const dragStart = (event: GlobalEventHandlersEventMap['mouseup']) => {
      drag.current = {
        drag: true,
        x: event.pageX,
        y: event.pageY,
      };
    };

    document?.addEventListener('mousedown', dragStart);
    document?.addEventListener('mouseup', dragEnd);

    return () => {
      document?.removeEventListener('mousedown', dragStart);
      document?.removeEventListener('mouseup', dragEnd);
    };
  }, [drag]);

  return (
    <>
      <div className={$.embla}>
        <div className={$.embla__viewport} ref={mainViewportRef}>
          <div className={$.embla__container}>
            {props.images?.map((image, index) => {
              const fluid = image?.image?.fluid;
              return (
                fluid && (
                  <div className={$.embla__slide} key={index}>
                    <div onClick={onImgClick} className={$.embla__slide__inner}>
                      <Img
                        key={index}
                        fluid={fluid}
                        loading="lazy"
                        critical={false}
                        className={$.embla__slide__img}
                        imgStyle={{
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
        <div>
          <div className={`${$.embla__container} ${$.embla__containerThumb}`}>
            <div className={$.embla__thumbs}>
              <div ref={thumbViewportRef} style={{ maxWidth: '100%' }}>
                <div style={{ maxWidth: '100%', whiteSpace: 'nowrap' }}>
                  {(props.images?.length ?? 0) > 1 &&
                    props.images?.map(
                      (img, index) =>
                        img?.image?.thumbnail && (
                          <Thumb
                            onClick={() => onThumbClick(index)}
                            selected={index === selectedIndex}
                            image={img?.image?.thumbnail}
                            key={index}
                          />
                        ),
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const WorkImages: React.FC<Props> = (props) => {
  return <EmblaCarousel {...props} />;
};
